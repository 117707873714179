<template>
  <el-tooltip :content="$t('PDF du bordereau')" placement="top-end">
    <a
      :class="props.class"
      @click="affDetail"
      @click.stop
    >
      <span class="svg-icon svg-icon-1 svg-icon-primary" :class="{'mx-0': !props.title}" :style="props.styleSVG">
        <inline-svg src="media/svg/pdf.svg"/>
      </span>
      {{ props.title }}
    </a>
  </el-tooltip>
  <el-drawer
    v-model="state.drawer"
    title=""
    :with-header="false"
    size="90%"
    modal-class="modal-offre"
  >
    <div class="card shadow-none" style="width: 100%; height: 100%">
      <!--begin::Header-->
      <div class="card-header" id="kt_activities_header">
        <h3 class="card-title fw-bolder text-dark">{{$t("PDF du bordereau")}}</h3>
        <div class="card-toolbar">
          <button
            type="button"
            class="btn btn-sm btn-icon btn-active-light-primary me-n5"
            @click="state.drawer = false"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
            </span>
          </button>
        </div>
      </div>
      <!--end::Header-->

      <!--begin::Body-->
      <div class="card-body position-relative" id="kt_activities_body">
        <div id="iframePDF" style="height:100% !important">
            <embed type="application/pdf" :src="state.myPDF " style="width:100%; height:100%" />
        </div>
      </div>
      <!--end::Body-->
      <!--end::Footer-->
    </div>
  </el-drawer>

</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from "vue";
import mAxiosApi from "@/api";

export default defineComponent({
  name: "Overview",
  components: {
    //
  },
  props: {
    title: String,
    class: String,
    styleSVG: String,
    bor_n_seq: String,
  },
  setup(props) {
    const state = reactive({
      drawer: false,
      myPDF: '',
      loaderEnabled : false,
    });


    const affDetail = async () => {
      let myListLiv = {} as any;
      myListLiv = await getAxios("/pdfBordereau/" + props.bor_n_seq);
      state.myPDF = "data:application/pdf;base64," + myListLiv.pdf + "#toolbar=1&navpanes=1&scrollbar=0";

      state.drawer = true;
    };

    onMounted(async () => {
      state.loaderEnabled = false;
    });

    return {
      affDetail,
      props,
      state
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
</script>
